<template>
    <div class="container">
        <div class="row">
            <h2 class="col-12 text-center mb-5 pt-5 fs-1 statement">
                Ein <span class="text-purple"><b>Standort</b></span>, eine <span class="text-purple"><b>Mail</b></span>, ein <span class="text-purple"><b>PDF!</b></span><br class="d-none d-xl-block"> <span class="text-purple">Mehr</span> brauchst <span class="text-purple">du</span> nicht!
            </h2>
            <div class="col-12">
                <form id="app" v-on:submit.prevent class="needs-validation" novalidate="">
                    <div class="row">
                        <div class="col-12 col-md-6 pb-4 pb-md-0">
                            <div class="row row-cols-2 text-center h-100 prices">
                                <div class="col">
                                    <div class="card border-0 shadow-sm h-100">
                                        <div class="card-header py-3">
                                            <h2 class="my-0 fw-normal">Basic</h2>
                                        </div>
                                        <div class="card-body px-0 d-flex flex-column justify-content-center">
                                            <small class="fw-light">1 Standort Analyse</small>
                                            <h1 class="card-title pricing-card-title">20€</h1>
                                            <small class="fw-light">(enthält mindestens 5 Simulationen)</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="card border-0 shadow-sm h-100">
                                        <div class="card-header py-3">
                                            <h2 class="my-0 fw-normal">Pro</h2>
                                        </div>
                                        <div class="card-body px-0 d-flex flex-column justify-content-center">
                                            <small class="fw-light">2 Standort Analysen</small>
                                            <h1 class="card-title pricing-card-title">je 15€</h1>
                                            <small class="fw-light">
                                                +15€ für jede Weitere
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6" id="customerData">
                            <div class="card shadow">
                                <div class="card-body">
                                    <h3 class="my-3">Persönliche Angaben</h3>
                                    <div class="row g-3">
                                        <div class="col-md-4">
                                            <div class="form-floating">
                                                <select class="form-select" id="sex" v-model="sex" required>
                                                    <option value="">Bitte wählen</option>
                                                    <option value="m">Herr</option>
                                                    <option value="f">Frau</option>
                                                    <option value="n">Divers</option>
                                                </select>
                                                <label for="sex">Anrede</label>
                                                <div class="invalid-feedback">
                                                    Bitte ein Geschlecht auswählen.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-floating">
                                                <input type="text" class="form-control" id="firstName"
                                                       v-model="firstname" required placeholder="Vorname">
                                                <label for="firstName">Vorname*</label>
                                                <div class="invalid-feedback">
                                                    Bitte einen Vornamen eintragen.
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="form-floating">
                                                <input type="text" class="form-control" id="lastName" v-model="lastname"
                                                       required="" placeholder="Nachname">
                                                <label for="lastName">Nachname*</label>
                                                <div class="invalid-feedback">
                                                    Bitte einen Nachnamen eintragen.
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12">
                                            <div class="form-floating">
                                                <input type="email" class="form-control" id="email" v-model="email"
                                                       required="" placeholder="E-Mail">
                                                <label for="email">Email*</label>
                                                <div class="invalid-feedback">
                                                    Bitte eine valide E-Mail Adresse eintragen.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row py-5">
                        <div class="col-12">
                            <div class="row g-3">
                                <div class="col-12" v-if="simulations">
                                    <nav>
                                        <div v-if="activePayment === false && activeAgbCheck === false" class="nav nav-tabs border-bottom-0" id="nav-tab" role="tablist">
                                            <button v-for="(simulation, index) in simulations" :key="index"
                                                    class="nav-link"
                                                    :class="[ simulation.active === true ? 'active' : ' bg-light-grey']"
                                                    id="nav-home-tab" data-bs-toggle="tab"
                                                    :data-bs-target="'#simulation-' + index" type="button" role="tab"
                                                    aria-controls="nav-home" aria-selected="true" v-on:click="setActiveStatus(index)"><h3 class="mb-0">
                                                {{ simulation.name }}</h3></button>
                                            <button v-if="simulations.length < 5" :disabled="loading === true" title="Simulation hinzufügen"
                                                    v-on:click="addSimulation" type="button" class="btn btn-dark add-simulation">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                     fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                                                    <path
                                                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                                </svg>
                                            </button>
                                        </div>
                                    </nav>
                                    <div class="tab-content shadow" id="nav-tabContent">
                                        <div v-for="(simulation, index) in simulations" :key="index"
                                             class="tab-pane fade simulation bg-white p-3 border border-top-0 border-bottom-0"
                                             :class="[ simulation.active === true ? 'show active' : '']"
                                             :id="'simulation-' + index" role="tabpanel" aria-labelledby="nav-home-tab">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-6">
                                                        </div>
                                                        <div class="col-6 d-flex">
                                                            <button v-if="this.simulations.length > 1 && activePayment !== true && activeAgbCheck !== true"
                                                                    :disabled="loading === true"
                                                                    title="Simulation entfernen"
                                                                    v-on:click="removeSimulation(index)" type="button"
                                                                    class="btn btn-danger btn-rounded">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                     height="16" fill="currentColor" class="bi bi-trash"
                                                                     viewBox="0 0 16 16">
                                                                    <path
                                                                        d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                                                    <path fill-rule="evenodd"
                                                                          d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6" v-if="activePayment === false && activeAgbCheck === false">
                                                    <div class="row">
                                                        <div class="col pt-3">
                                                            <label :for="'name-' + index" class="form-label">Miner Standort:</label>
                                                            <input type="text" class="form-control disabled"
                                                                   :id="'name-' + index"
                                                                   v-model="simulation.name" required="" :aria-describedby="'nameHelp-' + index" maxlength="120">
                                                            <div :id="'nameHelp-' + index" class="form-text fw-light">Gib deinem Standort einen individuellen Spitznamen</div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <!-- map -->
                                                        <div class="col-12 pt-3">
                                                            <div class="osm" :id="'osm-' + index" style="width: 650px; height: 600px; max-width: 100%; max-height: 100%;"></div>
                                                        </div>
                                                        <!-- end map -->
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-6" v-if="activePayment === false && activeAgbCheck === false">
                                                    <div class="row">

                                                    <!-- coords -->
                                                    <div class="col-md-6 pt-3"
                                                         v-if="simulation.flexSwitchCheckPosition === true">
                                                        <label :for="'lng-' + index" class="form-label">Längengrad</label>
                                                        <input type="text" class="form-control disabled" readonly
                                                               :id="'lng-' + index"
                                                               v-model="simulation.lng" required="">
                                                    </div>

                                                    <div class="col-md-6 pt-3"
                                                         v-if="simulation.flexSwitchCheckPosition === true">
                                                        <label :for="'lat-' + index" class="form-label">Breitengrad</label>
                                                        <input type="text" class="form-control disabled" readonly
                                                               :id="'lat-' + index"
                                                               v-model="simulation.lat" required="">
                                                    </div>
                                                        <p class="form-text col-12 fw-light">Setze den Standort deiner Antenne auf der Karte links (je genauer, desto besser)</p>
                                                    <!-- end coords -->

                                                    <!-- location details -->
                                                    <h3 class="col-12">
                                                        Details zu meinem Standort
                                                    </h3>

                                                        <div class="col-md-12 pt-3">
                                                            <div class="form-floating">
                                                                <select :aria-describedby="'antenna-desc-' + index" class="form-select" :id="'antenna-' + index"
                                                                        v-model="simulation.antenna">
                                                                    <option value="">Nur Standards</option>
                                                                    <option v-for="(antenna, index) in possibleAntennas.filter(function (el) { return el.default === false; })" :key="'antenna-' + index" :disabled="antenna.default" :value="antenna.id">{{ antenna.name }}</option>
                                                                </select>
                                                                <label :for="'antenna-' + index">Zusätzliche Antenne
                                                                </label>
                                                                <div :id="'antenna-desc-' + index" class="form-text fw-light">
                                                                    Die zusätzliche Antenne berücksichtigen wir für dich <b>kostenlos</b>!
                                                                    <br>
                                                                    Standartmäßig berücksichtigte Antennen: {{ concatDefaultAntennas() }}
                                                                </div>

                                                            </div>
                                                        </div>

                                                    <div class="col-12 pt-3">
                                                        <div class="form-floating">
                                                            <select class="form-select" :id="'placement-' + index"
                                                                    v-model="simulation.placement" required="" v-on:change="confirmPlacementSelection($event, index)">
                                                                <option value="Outdoor">Outdoor</option>
                                                                <option value="Indoor">Indoor</option>
                                                            </select>
                                                            <label :for="'placement-' + index">Die Antenne steht
                                                                {{ simulation.placement }}
                                                            </label>
                                                            <div class="invalid-feedback">
                                                                Bitte eine Platzierung auswählen.
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-12 pt-3">
                                                        <div class="form-floating">
                                                            <input type="number" class="form-control"
                                                                   :id="'antennaheight-' + index"
                                                                   v-model="simulation.antennaHeightFloors"
                                                                   min="1" max="63"  required="" v-on:change="calcMeters($event, index)"
                                                                    :aria-describedby="'antennaheighthelp-' + index">
                                                            <label :for="'antennaheight-' + index">Antennenhöhe in Stockwerken</label>
                                                            <div class="invalid-feedback">
                                                                Bitte ein Stockwerk auswählen.
                                                            </div>
                                                            <div class="form-text fw-light" :id="'antennaheighthelp-' + index">
                                                                Die Antennenhöhe in Metern wird automatisch berechnet, keine Sorge du kannst sie im Nachgang noch ganz individuell ändern
                                                            </div>
                                                        </div>
                                                    </div>

                                                        <div class="col-md-12 pt-3">
                                                            <div class="form-floating">
                                                                <input type="number" class="form-control"
                                                                       :id="'antennaHeightMeters-' + index"
                                                                       min="1" v-model="simulation.antennaHeightMeters" required="">
                                                                <label :for="'antennaHeightMeters-' + index">Antennenhöhe in Metern</label>
                                                                <div class="invalid-feedback">
                                                                    Bitte eine Höhe in Metern angeben.
                                                                </div>
                                                            </div>
                                                        </div>

                                                    <div class="col-md-12 pt-3">
                                                        <div class="form-floating">
                                                            <select class="form-select" :id="'terrain-' + index"
                                                                    v-model="simulation.terrain" required="">
                                                                <option value="urban">Städtisch -Umgeben von hohen Gebäuden (ab 5 Stockwerke)</option>
                                                                <option value="mix">Leicht Städtisch - Umgeben von Gebäuden (3-4 Stockwerke)</option>
                                                                <option value="suburbs">Vorstädtisch - Umgeben von Gebäuden (1-2 Stockwerke)</option>
                                                                <option value="wooded">Wäldlich - Umgeben von Bäumen und vereinzelt Häusern</option>
                                                                <option value="clear">Freie Sicht - Umgeben von Feldern und Wiesen (kaum Bäume in Sicht)</option>
                                                            </select>
                                                            <label :for="'terrain-' + index">Mein Standort
                                                                ist</label>
                                                            <div class="invalid-feedback">
                                                                Bitte einen Standort auswählen.
                                                            </div>
                                                        </div>
                                                    </div>
                                                        <div class="col-12 py-3 bg-white text-center">
                                                            <div class="button-wrapper">
                                                                <button :disabled="loading === true || simulations.length >= 5" title="Simulation hinzufügen"
                                                                        v-on:click="addSimulation" type="button" class="btn btn-dark btn-lg mx-auto d-flex align-items-center btn-rounded" data-toggle="tooltip" data-html="true" :title="tooltip">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                         fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                                                                        <path
                                                                            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                                                    </svg>
                                                                    Standort hinzufügen
                                                                </button>
                                                                <button class="btn btn-primary btn-lg mx-auto d-flex align-items-center btn-rounded"
                                                                        type="submit"
                                                                        v-on:click="saveRequest">
                                                                    <div v-if="saving === true" class="spinner-grow text-white" role="status">
                                                                    </div>
                                                                    <span :class="[saving === true ? 'ms-2' : '']">Bestellung abschließen</span>
                                                                </button>
                                                            </div>
                                                    </div>
                                                    <!-- end location details -->
                                                </div>
                                                </div>
                                                <div class="col-12 offset-md-2 col-md-8" v-if="activeAgbCheck === true">
                                                        <div class="row pb-2 agb" id="agbScrollBox">
                                                            <div v-html="agbText"></div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="form-check ms-3 pb-2" :class="[ageCheckError === true ? 'text-danger' : '']">
                                                                <input v-model="ageCheck" class="form-check-input custom-form-check-input" type="checkbox" value="" id="ageCheckbox">
                                                                <label class="form-check-label custom-form-check-label" for="ageCheckbox">
                                                                    Ich versichere, dass ich mindestens 18 Jahre alt bin.
                                                                </label>
                                                                <div class="invalid-feedback d-block" v-if="ageCheckError === true">
                                                                    <i class="bi bi-info-circle text-danger"></i> Du musst mindestens 18 Jahre alt sein.
                                                                </div>
                                                            </div>
                                                            <div class="form-check ms-3" :class="[agbError === true ? 'text-danger' : '']">
                                                                <input :disabled="agbScroll === false" v-model="agbCheck" class="form-check-input custom-form-check-input" type="checkbox" value="" id="agbCheckbox">
                                                                <label class="form-check-label custom-form-check-label" for="agbCheckbox">
                                                                    Ich habe die AGB gelesen und akzeptiere diese.
                                                                </label>
                                                                <div class="invalid-feedback d-block" v-if="agbError === true">
                                                                    <i class="bi bi-info-circle text-danger"></i> Du musst unseren AGB zustimmen.
                                                                </div>
                                                            </div>
                                                            <div class="form-check ms-3 pb-4" :class="[revocationError === true ? 'text-danger' : '']">
                                                                <input :disabled="agbCheck === false" v-model="revocationCheck" class="form-check-input custom-form-check-input" type="checkbox" value="" id="revocationCheck">
                                                                <label class="form-check-label custom-form-check-label" for="revocationCheck">
                                                                    Ich habe die in den AGB enthaltene Widerrufsbelehrung gelesen und verzichte wie in den AGB beschrieben auf mein Widerrufsrecht
                                                                </label>
                                                                <div class="invalid-feedback d-block" v-if="revocationError === true">
                                                                    <i class="bi bi-info-circle text-danger"></i> Du musst unserer Widerrufsbelehrung zustimmen.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    <div class="row">
                                                        <div class="col-12 text-center">
                                                            <button class="btn btn-primary btn-lg mx-auto d-flex align-items-center btn-rounded"
                                                                    type="button"
                                                                    v-on:click="goToActivePayment()">
                                                                Weiter um kostenpflichtig bestellen
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12" :class="[activePayment === false ? 'd-none' : '']">
                                                    <div class="col-12 col-md-8 offset-md-2">
                                                        <div v-if="activePayment === true">
                                                            <h2 class="d-flex justify-content-between align-items-center mb-3">
                                                                <span class="text-muted">Bestellübersicht</span>
                                                                <span class="badge badge-secondary badge-pill">{{ simulations.length }}</span>
                                                            </h2>
                                                            <ul class="list-group mb-3">
                                                                <li v-for="(simulation, index) in simulations" :key="index" class="list-group-item d-flex justify-content-between lh-condensed">
                                                                    <div>
                                                                        <h6 class="my-0">Standort #{{ index + 1 }}</h6>
                                                                        <small class="text-muted">{{ simulation.name }}</small>
                                                                    </div>
                                                                    <span class="text-muted">{{ this.getPrice(index + 1, simulations.length) }}€</span>
                                                                </li>
                                                                <li v-if="this.promoCode !== false" class="list-group-item d-flex justify-content-between bg-light position-relative promo">
                                                                    <div class="text-success">
                                                                        <h6 class="my-0">Gutscheincode - "{{ this.promoCode.code }}"</h6>
                                                                        <small>{{ this.promoCode.name }}</small>
                                                                    </div>
                                                                    <span class="text-success">-{{ this.promoCode.value * 100}}%</span>
                                                                    <button class="btn btn-danger" title="Entfernen" v-on:click="removePromoCode"><i class="bi bi-trash"></i></button>
                                                                </li>
                                                                <li class="list-group-item d-flex justify-content-between">
                                                                    <span>Total (EUR)</span>
                                                                    <strong>{{ this.calcPrice() }}€</strong>
                                                                </li>
                                                            </ul>
                                                            <form id="promo" v-on:submit="$event.preventDefault();redeemPromoCode()" v-on:submit.prevent class="p-2 border border-1 rounded mb-3">
                                                                <div class="input-group">
                                                                    <input v-model="enteredPromoCode" type="text" class="form-control custom-form-control" placeholder="Gutscheincode">
                                                                    <div class="input-group-append">
                                                                        <button :disabled="promoCode !== false" type="submit" class="btn btn-secondary">
                                                                            <span v-if="promoCodeLoading === false">Anwenden</span>
                                                                            <div class="spinner-border text-muted" role="status" v-else>
                                                                                <span class="visually-hidden">Loading...</span>
                                                                            </div>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <p v-if="promoCodeError" class="text-danger mb-0">Dieser Gutscheincode ist ungültig!</p>
                                                            </form>
                                                        </div>
                                                        <!-- Set up a container element for the button -->
                                                        <div id="smart-button-container">
                                                            <div style="text-align: center;">
                                                                <div :id="'paypal-button-container-' + index" :class="[paymentProcess === true ? 'd-none' : '']"></div>
                                                                <div :class="[paymentProcess !== true ? 'd-none' : '']">
                                                                    <h2 class="text-center text-muted">Deine Bestellung wird bearbeitet...</h2>
                                                                </div>
                                                                <div class="spinner-border text-muted" role="status" :class="[paymentProcess !== true ? 'd-none' : '']">
                                                                    <span class="visually-hidden">Loading...</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import 'ol/ol.css';
import * as ol from 'ol';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import * as proj from 'ol/proj';
import * as olGeom from 'ol/geom';
import * as olSource from 'ol/source';
import * as olLayer from 'ol/layer';
import VectorLayer from 'ol/layer/Vector';
import * as olStyle from 'ol/style';
import Geocoder from 'ol-geocoder';
export default {
    name: "checkout_component",
    data () {
        return {
            loading: true,
            firstname: '',
            lastname: '',
            email: '',
            sex: '',
            activePayment: false,
            paymentProcess: false,
            possibleMiners: [],
            possibleAntennas: [],
            simulations: [
            ],
            simulationBoilerplate: {
                name: '',
                flexSwitchCheckPosition: true,
                street: '',
                number: '',
                zip: '',
                state: '',
                country: '',
                miner: 'bobcat',
                lng: '',
                lat: '',
                placement: 'Outdoor',
                antennaHeightFloors: 1,
                antennaHeightMeters: 1,
                antenna: '',
                terrain: 'suburbs',
                map: null,
                readableMiner: 'Bobcat',
                active: true
            },
            markerStartCoord: [7.188713, 51.526332],
            saving: false,
            possibleUsers: [
                'Alex',
                'Lasse',
                'Martin',
                'Marek',
                'Kai',
                'Miriam',
                'Aline',
                'Oana',
                'Clara',
                'Fabi',
                'Marius',
                'Mauricio',
                'Niklas',
                'Raffy',
                'Christoph'
            ],
            possibleLocations: [
                'im Garten',
                'auf dem Dach',
                'auf dem Balkon',
                'am Fenster',
                'auf dem Parkplatz',
                'im Keller',
                'im Wohnzimmer',
                'im Schlafzimmer',
                'auf dem Dachboden'
            ],
            enteredPromoCode: '',
            promoCode: false,
            promoCodeError: false,
            promoCodeLoading: false,
            agbText: '',
            activeAgbCheck: false,
            agbCheck: false,
            agbError: false,
            ageCheck: false,
            ageCheckError: false,
            agbScroll: false,
            revocationCheck: false,
            revocationError: false,
        }
    },
    mounted() {
        let self = this;
        //get agb text
        this.getAgbText();
        //init possible antennas
        this.getPossibleAntennas();
        //add boilerplate object first
        this.simulations.push(_.cloneDeep(this.simulationBoilerplate));
        this.simulations[0].name = this.generateName();
        //init open layer map on first simulation object
        this.initOSMMap(0);
        //set loading false
        this.loading = false;
        this.initializePaypal(0);
    },
    methods: {
        getAgbText() {
            axios.get(window.baseurl + '/api/get-agb').then((res) => {
                this.agbText = res.data.agb;
                return true;
            }).catch((error) => {
                console.error('Invalid AGB!');
            });
        },
        /**
         * remove current promo code
         * @returns {boolean}
         */
        removePromoCode() {
            return this.promoCode = false;
        },
        /**
         * concat antennas
         * @returns {string}
         */
        concatDefaultAntennas() {
            let string = '';
            let filteredAntennas = this.possibleAntennas.filter(function (el) {
                return el.default === true;
            });
            filteredAntennas.forEach(function (el, index) {
                string += el.name;
                if (index < filteredAntennas.length - 1) {
                    string += ', ';
                }
            });
            return string;
        },
        /**
         * initialize paypal button
         * @param index
         */
        initializePaypal(index) {
            let self = this;
            paypal.Buttons({
                style: {
                    shape: 'rect',
                    color: 'blue',
                    layout: 'vertical',
                    label: 'buynow',
                },

                createOrder: function(data, actions) {
                    if(self.agbCheck === true) {
                        self.agbError = false;
                        self.paymentProcess = true;
                        return actions.order.create({
                            intent: 'CAPTURE',
                            purchase_units: [
                                {
                                    "amount":
                                        {
                                            "currency_code":"EUR",
                                            "value": self.calcPrice()
                                        }
                                }
                            ]
                        });
                    }
                    self.agbError = true;
                    return;
                },

                onApprove: function(data, actions) {
                    return actions.order.capture().then(function(orderData) {
                        gtag_report_conversion(window.baseurl);

                       axios.post(window.baseurl + '/api/save-request', {
                           email: self.email,
                           firstname: self.firstname,
                           lastname: self.lastname,
                           gender: self.sex,
                           requestedSimulations: self.simulations,
                           orderData: orderData,
                           discount: self.promoCode.value,
                           usedCode: self.promoCode.code,
                       }).then((res) => {
                           //go to another location
                           self.saving = false;
                           window.location.href = res.data.url;
                       })
                    });
                },

                onError: function(err) {
                    self.paymentProcess = false;
                },
                onCancel: function (data) {
                    self.paymentProcess = false;
                }
            }).render('#paypal-button-container-' + index);
        },
        /**
         * get Price
         * @param index
         * @returns {number}
         */
        getPrice(index, length) {
            switch (index) {
                case 1:
                    if(length == 1) {
                        return 20;
                    }
                    return 15;
                case 2:
                    return 15;
                case 3:
                    return 10;
                default:
                    return 10;
            }
        },
        /**
         * calc price
         * @returns {number}
         */
        calcPrice() {
            let price = 20;
            switch (this.simulations.length) {
                case 1:
                    price = 20;
                    break;
                case 2:
                    price = 30;
                    break;
                case 3:
                    price = 40;
                    break;
                case 4:
                    price = 50;
                    break;
                case 5:
                    price = 60;
                    break;
            }

            if(this.promoCode !== false) {
                if(this.promoCode.value < 1 && this.promoCode.value > 0) {
                    return price - (price * this.promoCode.value);
                }
                return price;
            }
            return price;
        },
        /**
         * check entered promo code with api
         * @returns {boolean}
         */
        checkPromoCode() {
            let self = this;
            self.promoCodeLoading = true;
            //fill me with promo code validitaion
            if(this.enteredPromoCode != '') {
                    axios.get(window.baseurl + '/api/check-promocode?code=' + this.enteredPromoCode).then((res) => {
                        self.promoCode = res.data.promo;
                        self.promoCodeError = false;
                        self.promoCodeLoading = false;
                        return true;
                    }).catch((error) => {
                        self.promoCodeError = true;
                        console.error('Invalid promo-code!');
                        self.promoCodeLoading = false;
                        return false;
                    });
                return true;
            }
            return false;
        },
        /**
         * redeem given promo code
         */
        redeemPromoCode() {
            this.promoCodeError = false;
            if(this.checkPromoCode() === true) {

            } else {
                this.promoCodeError = true;
            }
        },
        /**
         * calculate meters from selected floors
         * @param event
         * @param index
         */
        calcMeters(event, index) {
            this.simulations[index].antennaHeightMeters = this.simulations[index].antennaHeightFloors * 3;
            return;
        },
        /**
         * generates random name
         */
        generateName() {
            return 'Bei ' +
                this.possibleUsers[Math.floor(Math.random()*this.possibleUsers.length)] +
                ' ' +
                this.possibleLocations[Math.floor(Math.random()*this.possibleLocations.length)]
        },
        /**
         * confirm indoor selection
         */
        confirmPlacementSelection(event, index) {
            if(event.target.value === 'Indoor') {
                //create content element
                let content = document.createElement("p");
                content.innerHTML =  'Eine Outdoor Platzierung erzielt die besten Ergebnisse und ist in vielen Fällen auch ohne Dachzugang, Garten oder Balkon möglich. Es gibt unzählige <a target="_blank" rel="noopener" title="Zu den Halterungen" href="https://hexaspot.com/collections/antenna-mounting?ref=HeliumDE">Halterungen</a>, die dir helfen können. Wir empfehlen dir zu überprüfen, ob eine Outdoorplatzierung möglich ist.'
                swal( {
                        content: content,
                        buttons: {
                            cancel: "Trotzdem Indoor platzieren",
                            confirm: "Bei Outdoor bleiben",
                        },
                }).then((value) => {
                    if(value === true) {
                        this.simulations[index].placement = 'Outdoor';
                    }
                });
            }
        },
        /**
         * get all possible Antennas from API
         */
        getPossibleAntennas() {
            axios.get(window.baseurl + '/api/get-antennas').then((res) => {
                this.possibleAntennas = res.data.possibleAntennas;
            }).catch((error) => {
                if(error.response.status) {
                    switch (error.response.status) {
                        case 422:
                            swal("Whoops!", error.response.data.message, "error");
                            break;
                        case 500:
                            swal("Whoops!", "Anfrage konnte nicht verarbeitet werden. Bitte versuche es später erneut.", "error");
                            break;
                    }
                } else {
                    swal("Whoops!", "Anfrage konnte nicht verarbeitet werden. Bitte versuche es später erneut.", "error");
                }
                return false;
            })
        },
        /**
         * init open layer map, and assign to simulation object
         * @param index
         */
        initOSMMap(index, ) {
            let self = this;
            //init map
            setTimeout(function() {
                self.simulations[index].map = new Map({
                    target: 'osm-' + index,
                    layers: [
                        new TileLayer({
                            source: new XYZ({
                                url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                            })
                        }),
                    ],
                    view: new View({
                        center: proj.fromLonLat(self.markerStartCoord),
                        zoom: 5
                    })
                });


                self.simulations[index].map.on('singleclick', function (event) {
                    let coordinates = proj.transform(event.coordinate, 'EPSG:3857', 'EPSG:4326');
                    self.simulations[index].lat = coordinates[1];
                    self.simulations[index].lng = coordinates[0];

                    self.osMapSetPointer(this, coordinates);
                });

                let geocoder = new Geocoder('nominatim', {
                    provider: 'osm',
                    key: '',
                    lang: 'de-DE', //en-US, fr-FR
                    placeholder: 'Suche...',
                    targetType: 'text-input',
                    limit: 5,
                    keepOpen: true,
                    autoComplete: true
                });
                self.simulations[index].map.addControl(geocoder);

                geocoder.on('addresschosen', function(evt){
                    let coordinates = proj.transform(evt.coordinate, 'EPSG:3857', 'EPSG:4326');
                    self.simulations[index].lat = coordinates[1];
                    self.simulations[index].lng = coordinates[0];
                    self.osMapSetPointer(self.simulations[index].map, coordinates);

                    //navigate and zoom to position
                    self.simulations[index].map.getView().setZoom(14);
                    self.simulations[index].map.getView().setCenter(proj.fromLonLat(coordinates));
                });

            }, 500)

        },
        /**
         * set os map pointer
         * @param map
         * @param coordinates
         */
        osMapSetPointer(map, coordinates) {
            //reverse layers and remove all layer except first tile layer
            let reversedArray = map.getLayers().array_.reverse();
            reversedArray.forEach(function (layer, index) {
                if (index < reversedArray.length - 1 && layer instanceof VectorLayer || layer instanceof olLayer.Vector) {
                    map.removeLayer(layer);
                }
            });

            let marker = new ol.Feature({
                geometry: new olGeom.Point(proj.fromLonLat(coordinates))
            });

            marker.setStyle(new olStyle.Style({
                image: new olStyle.Icon(({
                    crossOrigin: 'anonymous',
                    src: window.baseurl + '/assets/icons/polygon-marker.png',
                }))
            }));

            //marker layer
            let layer = new olLayer.Vector({
                source: new olSource.Vector({
                    features: [
                        marker
                    ]
                })
            });
            //append new layer to map
            map.addLayer(layer);
        },
        /**
         * add new simulation boilerplate to array
         */
        addSimulation() {
            let self = this;
            if (this.simulations.length <= 4) {
                this.resetActiveStatus();

                this.simulations.push(_.cloneDeep(this.simulationBoilerplate));
                //init new osm map for new object
                this.initOSMMap(this.simulations.length - 1);
                this.simulations[this.simulations.length - 1].name = this.generateName();
                setTimeout(function () {
                    self.initializePaypal(self.simulations.length - 1)
                }, 200)
                return;
            }
            swal("Sorry!", "Wir können aktuell maximal 5 Standorte simulieren.", "info");
        },
        /**
         * set active status of simulation
         * @param index
         */
        setActiveStatus(index) {
          this.resetActiveStatus();
          this.simulations[index].active = true;
        },
        /**
         * reset all active status
         */
        resetActiveStatus() {
            let self = this
            //reset active status
            this.simulations.forEach((elem, index) => {
                self.simulations[index].active = false;
            });
        },
        /**
         * remove simulation from array
         * @param pos
         */
        removeSimulation(index) {
            swal("Möchtest du diesen Standort wirklich entfernen?", {
                buttons: {
                    cancel: "Nein",
                    confirm: "Ja",
                },
            })
                .then((value) => {
                    if(value === true) {
                        if(this.simulations.length > 1) {
                            this.simulations.splice(index, 1);
                            this.resetActiveStatus()
                            this.simulations[0].active = true;
                            this.$forceUpdate();
                        }
                    }
                });

            return
        },
        /**
         * emit save request event
         * @param event
         */
        saveRequest(event) {
            // Example starter JavaScript for disabling form submissions if there are invalid fields
            (function () {
                'use strict'

                // Fetch all the forms we want to apply custom Bootstrap validation styles to
                var forms = document.querySelectorAll('.needs-validation')

                // Loop over them and prevent submission
                Array.prototype.slice.call(forms)
                    .forEach(function (form) {
                        form.addEventListener('submit', function (event) {
                            form.checkValidity()
                            event.preventDefault()
                            event.stopPropagation()
                            form.classList.add('was-validated')
                        }, false)
                    })
            })()
            this.validateSimulations();
        },
        /**
         * perform save request on all simulations
         */
        doSaveRequest() {
            this.resetActiveStatus();
            this.simulations[0].active = true;
            this.activeAgbCheck = true;
            this.scrollTo('nav-tabContent');
        },
        /**
         * go to active payment from agb step
         */
        goToActivePayment() {
            if(this.agbCheck === true && this.ageCheck === true && this.revocationCheck === true) {
                this.activeAgbCheck = false;
                this.activePayment = true;
                this.scrollTo('nav-tabContent');
            } else {
                if(this.agbCheck !== true) {
                    this.agbError = true;
                }
                if(this.ageCheck !== true) {
                    this.ageCheckError = true;
                }
                if(this.revocationError !== true) {
                    this.revocationError = true;
                }
            }
        },
        /**
         * validate simulations
         */
        validateSimulations() {
                this.saving = true;
                let self = this;
                self.simulations.forEach(function (element, index) {
                    self.simulations[index].map = '';
                });
                axios.post(window.baseurl + '/api/validate-simulations', {
                    simulations: this.simulations,
                    personalData: {
                        firstname: this.firstname,
                        lastname: this.lastname,
                        email: this.email,
                        sex: this.sex,
                    }
                }).then((res) => {
                   this.doSaveRequest();
                }).catch((error) => {
                    switch (error.response.status) {
                        case 422:
                            swal("Whoops!", error.response.data.message, "error");
                            break;
                        case 500:
                            swal("Whoops!", "Something went wrong, please try again later", "error");
                            break;
                    }
                    this.saving = false;
                    this.scrollTo('customerData');
                })
        },
        /**
         * simple scroll function
         * @param elemId
         */
        scrollTo(elemId) {
            let yOffset = -140;
            let element = document.getElementById(elemId);
            let y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
        },
        /**
         * get readable miner namer from possible miners
         * @param miner
         */
        getReadableMiner(miner) {
            if(this.possibleMiners.length > 0) {
                let readableMiner = this.possibleMiners.filter(obj => {
                    return obj.id === miner;
                });
                return readableMiner[0].name;
            }
        },
        /**
         * set readable miner attr of simulation
         * @param index
         */
        setReadableMiner(index) {
            return this.simulations[index].readableMiner = this.getReadableMiner(this.simulations[index].miner);
        }
    },
    computed: {
    },
    watch: {
        /**
         * need to watch agb check event to initialize scroll handler
         */
        activeAgbCheck: {
            handler(item) {
                let self = this;
                setTimeout(function () {
                    let el = document.getElementById('agbScrollBox');
                    if (el) {
                        el.addEventListener('scroll', function (e) {
                            if (this.scrollHeight - this.scrollTop - this.clientHeight <= 0) {
                                self.agbScroll = true;
                            }
                        });
                    }
                }, 200)
            }
        },
        agbCheck: {
            handler(item) {
                if (item === false) {
                    this.revocationCheck = false;
                }
            }
        }
    },
}
</script>
