//get nav offset + correct factor
var offset = document.getElementById('navbar-scrollspy').offsetHeight + 20;

$('.smoothscroll').click(function(event) {
    // Make sure this.hash has a value before overriding default behavior
    if (this.hash !== "") {

        // Prevent default anchor click behavior
        event.preventDefault();

        // Store hash
        var hash = this.hash;

        if(document.querySelectorAll(hash).length > 0) {

            // Using jQuery's animate() method to add smooth page scroll
            // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
            $('html, body').animate({
                scrollTop: $(hash).offset().top - offset
            }, 0, function () {

                // Add hash (#) to URL when done scrolling (default click behavior)
                window.location.hash = hash;
            });
        } else {
            window.location = this.href;
        }

    } // End if

});

window.sr.reveal('.fadein-right' ,{
    duration: 1500,
    origin: 'right',
    distance: '300px'
});

window.sr.reveal('.fadein-left' ,{
    duration: 1500,
    origin: 'left',
    distance: '300px'
});
