/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
window.$ = require('jquery');
import ScrollReveal from 'scrollreveal'
window.sr = new ScrollReveal();
require('./_scrollspy');
import swal from 'sweetalert';
import { createApp } from 'vue'
import CheckoutComponent from './components/CheckoutComponent.vue';

window.swal = swal;

const app = createApp({});


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

//Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */


// registers our HelloWorld component globally
app.component('checkout-component', CheckoutComponent);

// mount the app to the DOM
app.mount('#app');

/**
 * easter egg
 * @type {Egg}
 */
var egg = new Egg("up,up,down,down,left,right,left", function() {
        window.location = "https://www.youtube.com/watch?v=o-YBDTqX_ZU";
}).listen();
